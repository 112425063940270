import { useEffect, useState } from 'react'
import { withRouter } from '../utils'
import swal from 'sweetalert'
import axios from 'axios'
import bcrypt from 'bcryptjs'
import '../App.css'

var salt = bcrypt.genSaltSync(10)

function Login({ navigate }) {
  const apiHost = process.env.REACT_APP_APIHOST
  const [username, setUsername] = useState(null)
  const [password, setPassword] = useState(null)

  useEffect(() => {
    let token = localStorage.getItem('token')

    if (token) {
      navigate('/map')
    } 
  }, [navigate])


  const login = async () => {
    const pwd = bcrypt.hashSync(password, salt)

    await axios
      .post(`${apiHost}/heatmap/login`, {
        username: username,
        password: pwd,
      })
      .then((res) => {
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('user_id', res.data.id)
        navigate('/map')
      })
      .catch((err) => {
        swal({
          text:
            err && err.response
              ? err.response.data.errorMessage
              : 'Something went wrong.',
          icon: 'error',
          type: 'error',
        })
      })
  }

  return (
    <>
      <div class='panel_blur'></div>
      <div class='panel'>
        <div class='panel__form-wrapper'>
          <ul class='panel__headers'>
            <li class='panel__header'>
              <div class='panel__link'>
                <img src='icons/logo-white.png' width='100' />
              </div>
            </li>
            <li class='panel__header active'>
              <a
                href='#login-form'
                role='button'
                style={{ fontSize: '18px', fontWeight: '600' }}
              >
                Sign In
              </a>
            </li>
          </ul>

          <div class='panel__forms'>
            <form class='form panel__login-form' id='login-form'>
              <div class='form__row'>
                <input
                  type='text'
                  id='username'
                  class='form__input'
                  name='login-username'
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value)
                  }}
                  required
                />
                <span class='form__bar'></span>
                <label for='username' class='form__label'>
                  Username
                </label>
                <span class='form__error'></span>
              </div>
              <div class='form__row'>
                <input
                  type='password'
                  id='password'
                  class='form__input'
                  name='login-pass'
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                  required
                />
                <span class='form__bar'></span>
                <label for='password' class='form__label'>
                  Password
                </label>
                <span class='form__error'></span>
              </div>
              <div class='form__row'>
                <input class='form__submit' value='Login' onClick={login} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(Login)
