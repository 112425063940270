import { useNavigate } from "react-router-dom";
import React from "react";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    return <Component navigate={navigate} {...props} />;
  };
  return Wrapper;
};

export const formatCoordinates = (latitude, longitude) => {
  const latDirection = latitude >= 0 ? 'N' : 'S';
  const lonDirection = longitude >= 0 ? 'E' : 'W';

  const formattedLatitude = Math.abs(latitude).toFixed(4);
  const formattedLongitude = Math.abs(longitude).toFixed(4);

  return `${formattedLatitude}° ${latDirection}, ${formattedLongitude}° ${lonDirection}`;
}