import { useEffect, useState } from 'react'
import { withRouter } from '../utils'
import Map from '../components/Map'
import '../App.css'
import axios from 'axios'

function App({ navigate }) {
  const [points, setPoints] = useState(null)
  const [events, setEvents] = useState([])
  const apiHost = process.env.REACT_APP_APIHOST

  useEffect(() => {
    const fetchData = async () => {
      let dataUrl = `${apiHost}/heatmap/data`
      let options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept:
            'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8',
        },
      }

      const dataAction = await fetch(dataUrl, options)

      const pointData = dataAction.json().then(async (response) => {
        setPoints(response)
      })

      return pointData
    }

    let token = localStorage.getItem('token')

    if (!token) {
      navigate('/')
    } else {
      fetchData()
    }
  }, [apiHost, navigate])

  const fetchEvents = async (topLeft, bottomRight) => {
    await axios
      .post(`${apiHost}/heatmap/events`, {
        topLeft: topLeft,
        bottomRight: bottomRight,
      })
      .then((res) => {
        setEvents(res.data)
      })
  }

  return (
    <div className='app'>
      <Map coords={points} events={events} fetchEvents={fetchEvents} />
    </div>
  )
}

export default withRouter(App)
