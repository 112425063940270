import React, { useEffect, useRef, useState } from 'react'
import { formatCoordinates } from '../utils'
import Loader from 'react-js-loader'
import moment from 'moment'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import 'leaflet.heat'
import _ from 'lodash'

export default function Map({ coords, events, fetchEvents = () => {} }) {
  const [showEvents, setShowEvents] = useState(true)
  const fetchEventsRef = useRef(_.debounce(fetchEvents, 500))

  useEffect(() => {
    if (!coords || coords.length === 0) {
      return
    }

    var map = L.map('map', {
      center: new L.LatLng(31.536, 34.4907),
      attributionControl: false,
      zoom: 12,
    })

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map)

    const points = coords
      ? coords.map((p) => {
          return [p[0], p[1], p[2]]
        })
      : []

    if (points.length > 0) {
      L.heatLayer(points).addTo(map)
    }

    map.on('moveend', () => {
      const bounds = map.getBounds()

      const topLeft = bounds.getNorthWest()
      const bottomRight = bounds.getSouthEast()

      fetchEventsRef.current(topLeft, bottomRight)
    })

    const initialBounds = map.getBounds()
    const initialTopLeft = initialBounds.getNorthWest()
    const initialBottomRight = initialBounds.getSouthEast()
    fetchEventsRef.current(initialTopLeft, initialBottomRight)

    return () => {
      map.remove()
    }
  }, [coords, fetchEventsRef])

  return (
    <div className='map-div'>
      <div>
        <div className='map-toolbar'>
          <img src='icons/logo.png' className='logo' />
          <div class='map-actions-div'>
            <div class='map-actions'>
              <img
                src='icons/filters.png'
                onClick={() => {
                  setShowEvents(!showEvents)
                }}
                className='map-action-img'
              />
              <img src='icons/map.png' className='map-action-img' />
              <img src='icons/timeline.png' className='map-action-img' />
              <img src='icons/graph.png' className='map-action-img' />
              <img src='icons/alerts.png' className='alerts' />
            </div>
            <img src='icons/menu.png' className='menu' />
          </div>
        </div>
        {coords && coords.length ? (
          <div className='map-container'>
            {events && showEvents ? (
              <div className='events-container'>
                <div className='events'>
                  <div className='header'>
                    <div className='text'>
                      <span> Major events</span>
                      <div class='circle'>{events.length}</div>
                    </div>
                    <img src='icons/filters.png' className='events-filter' />
                  </div>
                  <hr className='header-hr'></hr>
                  {events.map((event, idx) => (
                    <div className='event'>
                      <div className='event-title'>
                        <div className='event-location'>
                          <img src='icons/pin.png' className='event-pin' />
                          <span> {event.country} </span>
                        </div>
                        <div className='event-date'>
                          <img src='icons/date.png' className='event-clock' />
                          <span>
                            {moment(new Date(event.timestamp * 1000)).format(
                              'D MMM YYYY, h:mmA'
                            )}
                          </span>
                        </div>
                      </div>
                      <div className='event-subtitle'>
                        <span>
                          {formatCoordinates(event.latitude, event.longitude)}
                        </span>
                        <span>
                          {moment(new Date(event.timestamp * 1000)).fromNow()}{' '}
                        </span>
                      </div>
                      <p className='event-desc'>{event.notes}</p>
                      <div class='event-imp'>
                        <div class='event-circle-red'></div>{' '}
                        <span> Critical </span>
                      </div>
                      {idx !== events.length - 1 ? (
                        <hr className='event-hr'></hr>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div
              id='map'
              style={{
                height: '95vh',
                width: '100vw',
                position: 'relative',
                zIndex: 4,
              }}
            ></div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '95vh',
            }}
          >
            <Loader
              type='spinner-default'
              bgColor='#FFFFFF'
              color='#FFFFFF50'
              size={50}
            />
          </div>
        )}
      </div>
    </div>
  )
}
